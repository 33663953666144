<template>
  <v-container class="translate-up-25">
    <s-tabs class="mb-2 mt-6">
      <v-tab :to="{ name: 'tokens' }">Access Tokens</v-tab>
    </s-tabs>
    <router-view />
  </v-container>
</template>
<script>
export default {
  name: 'Settings',
}
</script>
